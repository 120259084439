import React, { useState } from "react";
import {
  Card,
  Container,
  CardContent,
  Typography,
  LinearProgress,
  Box,
  Grid,
} from "@mui/material";
import FormIPSolutions from "./FormIPSolutions";

import Title from "./Title";
import Account from "./Account";

function FormPage() {
  console.log("This is showing?");
  return (
    <Container>
      <Grid item xs={12} textAlign="center">
        <Title>Asset Management Quickscan</Title>
      </Grid>
      <Grid item xs={12}>
        <Card color="secondary">
          <CardContent>
            <FormIPSolutions />
          </CardContent>
        </Card>
      </Grid>
    </Container>
  );
}

export default FormPage;

import { Button, CssBaseline, ThemeProvider, Typography } from "@mui/material";
import {
  BrowserRouter,
  Navigate,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import theme from "./theme";
import ResultsPage from "./components/ResultsPage";
import FormPage from "./components/FormPage";
import Header from "./components/Header";
import Account from "./components/Account";
import ThankYou from "./components/Thankyou";
import VragenInkloppen from "./components/VragenInkloppen";
import Welcome from "./components/Welcome";
import Export from "./components/Export";

function Router() {
  return useRoutes([
    {
      path: "",
      element: <Header />,
      children: [
        {
          path: "welcome",
          element: <Welcome />,
        },
        {
          path: "quickscan",
          element: <Navigate to="0" />,
        },
        {
          path: "quickscan/:page",
          element: <FormPage />,
        },
        {
          path: "account",
          element: <Account />,
        },
        {
          path: "opgeslagen/:reference",
          element: <ThankYou />,
        },

        {
          path: "",
          element: <Navigate to="welcome" />,
        },
        {
          path: "create",
          element: <VragenInkloppen />,
        },
      ],
    },
    {
      path: "export",
      element: <Export />,
    },
    {
      path: "export/:id",
      element: <Export />,
    },
    {
      path: "uitslagen/:id",
      element: <ResultsPage />,
    },
    {
      path: "*",
      element: <NotFound404 />,
    },
  ]);
}

function NotFound404() {
  const navigate = useNavigate();
  return (
    <div>
      <Typography>Deze pagina is niet gevonden.</Typography>
      <Button
        onClick={() => {
          navigate("quickscan");
        }}
      >
        Naar Asset Management Quickscan
      </Button>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

import React, { useState } from "react";
import FormikMuiForm, { createLabel } from "./FormikMuiForm";
import * as yup from "yup";
import {
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  ListItem,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import { useCreateAccountAndSubmitFormMutation } from "../redux/api";
import { ErrorMessage, FormikValues } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  Answers_Insert_Input,
  CreateAccountAndSubmitFormMutationMutation,
} from "../graphqlTypes";
import { useNavigate } from "react-router-dom";
import { ClientError } from "graphql-request";
import { SerializedError } from "@reduxjs/toolkit";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { resetAnswers } from "../redux/form";
// import { Container } from './styles';

const Account: React.FC = () => {
  const [submitForm, isLoading] = useCreateAccountAndSubmitFormMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { answers } = useSelector((state: RootState) => state.form_answers);
  const labeledValues = [
    createLabel("Uw naam", "person_name", yup.string().required(), ""),
    createLabel("Bedrijfsnaam", "company_name", yup.string().required(), ""),
    createLabel("Email adres", "email", yup.string().email().required(), ""),
    createLabel("Telefoon nummer", "phone_numbers", yup.string(), ""),
    // createLabel(
    //   "Aantal werknemers",
    //   "num_empolyees",
    //   yup.number().required(),
    //   ""
    // ),
    // createLabel(
    //   "Maintenance budget",
    //   "num_maintenance_budget",
    //   yup.number().required(),
    //   ""
    // ),
  ];
  const [empoyees, setEmpoyees] = useState<number | null>(null);
  const [budget, setBudget] = useState<number | null>(null);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);

  const mcEmpolyees = [10, 25, 50];
  const mcMaintenaceBudget = [500000, 1000000, 5000000];
  // agree_privacy_statement
  const submit = async (values: FormikValues) => {
    setError2(false);
    setError1(false);
    if (!budget) {
      setError2(true);
    }
    if (!empoyees) {
      setError1(true);
    }

    if (!budget || !empoyees) {
      return;
    }
    console.log("Submit this..");
    const { company_name, person_name, phone_numbers, email } = values;

    const num_empolyees = BigInt(empoyees);
    const num_maintenance_budget = BigInt(budget);
    console.log(answers);
    const question_ids = Object.keys(answers);

    const answers_correct: Answers_Insert_Input[] = question_ids.map((id) => {
      return {
        question_id: id,
        answer: Number(answers[id]),
      };
    });

    const response = await submitForm({
      company_name,
      num_empolyees,
      num_maintenance_budget,
      person_name,
      phone_numbers,
      answers: answers_correct,
      email,
    }).catch((e) => {
      console.error("Error sending form: ", e);
      return Promise.reject(e);
    });

    // @ts-expect-error
    const {
      data,
      error,
    }: {
      data: CreateAccountAndSubmitFormMutationMutation;
      error: Pick<ClientError, "name" | "message" | "stack"> | SerializedError;
    } = response;
    const reference = data?.insert_customers_one?.id;

    if (error || !reference) {
      console.error("Er is iets mis gegaan...");
    }

    console.log("This is the data", data);
    console.log("Reference", reference);
    console.log("This is the error", error);

    if (reference) {
      dispatch(resetAnswers());
      navigate(`../opgeslagen/${reference}`);
    } else {
      setErrorMessage("Reference kon niet worden geladen...");
    }
  };

  return (
    <FormikMuiForm
      errorMessage={errorMessage}
      isLoading={isLoading.status === "pending"}
      formik={{ onSubmit: submit }}
      inputProps={{ fullWidth: true }}
      labeledValues={labeledValues}
      submitButtonText="Verzenden"
      component={
        <>
          <ListItem>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Aantal werknemers
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                value={empoyees}
                label="Aantal werknemers"
                onChange={(v) => setEmpoyees(Number(v.target.value))}
              >
                <MenuItem value={5}>Minder dan {mcEmpolyees[0]}</MenuItem>
                {mcEmpolyees.map((num, index) => {
                  if (index == mcEmpolyees.length - 1) {
                    return null;
                  }
                  return (
                    <MenuItem value={num}>
                      Tussen de {num + 1} - {mcEmpolyees[index + 1]}
                    </MenuItem>
                  );
                })}
                <MenuItem value={100}>
                  Meer dan dan {mcEmpolyees[mcEmpolyees.length - 1] + 1}
                </MenuItem>
              </Select>
              <Typography variant="subtitle2" color="error">
                {error1 ? "Verplicht veld" : null}
              </Typography>
            </FormControl>
          </ListItem>

          <ListItem>
            <FormControl fullWidth>
              <InputLabel id="maintenance-label">Maintenance Budget</InputLabel>
              <Select
                labelId="maintenance-label"
                id="maintenance-select"
                fullWidth
                value={budget}
                label="Aantal werknemers"
                onChange={(v) => setBudget(Number(v.target.value))}
              >
                <MenuItem value={250000}>
                  Minder dan {stringifyBudget(mcMaintenaceBudget[0])}
                </MenuItem>
                {mcMaintenaceBudget.map((num, index) => {
                  if (index == mcMaintenaceBudget.length - 1) {
                    return null;
                  }
                  return (
                    <MenuItem value={num}>
                      Tussen de {stringifyBudget(num)} -{" "}
                      {stringifyBudget(mcMaintenaceBudget[index + 1] - 1)}
                    </MenuItem>
                  );
                })}
                <MenuItem value={2000000}>
                  Meer dan dan{" "}
                  {stringifyBudget(
                    mcMaintenaceBudget[mcMaintenaceBudget.length - 1]
                  )}
                </MenuItem>
              </Select>
              <Typography variant="subtitle2" color="error">
                {error2 ? "Verplicht veld" : null}
              </Typography>
            </FormControl>
          </ListItem>
        </>
      }
    />
  );
};

function stringifyBudget(budget: number): string {
  return budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export default Account;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Answer {
  [question_id: string]: string;
}
interface InitialState {
  answers: Answer;
}
const initialState: InitialState = {
  answers: {},
};

const formSlice = createSlice({
  name: "form_answers",
  initialState,
  reducers: {
    resetAnswers: (state) => {
      state.answers = {};
    },
    saveAnswer: (state, action: PayloadAction<Answer>) => {
      state.answers = action.payload;
    },
  },
});

export const { saveAnswer, resetAnswers } = formSlice.actions;

export default formSlice;

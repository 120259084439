import {
  AnyAction,
  combineReducers,
  configureStore,
  createAction,
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, createMigrate } from "redux-persist";
import thunk from "redux-thunk";
import formSlice from "./form";

import { quickscanApi } from "./api";
// import networkReducer from "./network";

export const resetStore = createAction("RESET_STORE");
export const updateStore = createAction("UPDATE_STORE");

const rootReducer = (state: any, action: AnyAction) => {
  if (resetStore.match(action)) {
    return reducers(undefined, action);
  }

  return reducers(state, action);
};

const reducers = combineReducers({
  [formSlice.name]: formSlice.reducer,
  [quickscanApi.reducerPath]: quickscanApi.reducer,

  // network: networkReducer,
});
const MIGRATION_DEBUG = false;
const persistConfig = {
  key: "root",
  storage,
  version: 9,
  debug: MIGRATION_DEBUG,
  blacklist: [
    [formSlice.name].toString(),
    [quickscanApi.reducerPath].toString(),
  ],

  //   migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk, quickscanApi.middleware],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;

import { Chart as ChartJS, registerables, ChartData, Chart } from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(...registerables);
ChartJS.defaults.color = "#000";
type props = {
  dataSet: ChartData<"bar">;
  title: string;
};

function ChartComp({ dataSet, title }: props) {
  const yLabels = [
    "",
    "",
    "Developing",
    "",
    "Basic",
    "",
    "Professional",
    "",
    "Excellence",
  ];
  return (
    <Bar
      style={{ color: "black" }}
      data={dataSet}
      options={{
        plugins: {
          title: {
            display: true,
            text: title,
          },
          legend: {
            display: false,
            position: "bottom",
          },
        },
        scales: {
          y: {
            max: 4,
            ticks: {
              callback: (value, index, ticks) => {
                return yLabels[index];
              },
            },
          },
        },
      }}
    />
  );
}

export default ChartComp;

import * as yup from "yup";
import { LabelInterface } from "../components/FormIPSolutions";
/**
 * Question class
 *
 */
class Question {
  // You can use answer?: string to indicate that this value can be undefined.
  answer: number = -1;
  labeledValue: LabelInterface;
  text: string = "";

  // Prefix the _ if its a private variable
  constructor(
    _text: string,
    public id: string,
    public explaination: string,
    public possible_answers: number[]
  ) {
    this.labeledValue = {
      label: id,
      field: id,
      schema: yup
        .number()
        .oneOf(possible_answers, `Deze vraag is verplicht`)
        .required(),

      initValue: -1,
      type: "number",
    };
    this.text = _text.charAt(0).toUpperCase() + _text.slice(1);
  }
}

export default Question;

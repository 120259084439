import {
  Box,
  Button,
  List,
  ListItem,
  Grid,
  ButtonGroup,
  Typography,
  ListItemText,
  LinearProgress,
  Divider,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import * as yup from "yup";
import { Form, Formik, FormikValues, ErrorMessage } from "formik";
import { LoadingButton } from "@mui/lab";
import Question from "../classes/question";
import { Params, useNavigate, useParams } from "react-router-dom";
import {
  Category,
  useCreateAccountAndSubmitFormMutation,
  useGetQuestionsQuery,
} from "../redux/api";
import Account from "./Account";
import { useDispatch, useSelector } from "react-redux";
import { saveAnswer } from "../redux/form";
import { RootState } from "../redux/store";

export interface LabelInterface {
  label: string;
  field: string;
  schema: yup.AnySchema;
  initValue: any;
  type: number | string;
}

/**
 *
 * @returns
 */
const FormIPSolutions: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  //@ts-expect-error
  const { page }: { page: string } = useParams();
  let activeCategory = Number(page);
  const { answers: saved_answers } = useSelector(
    (state: RootState) => state.form_answers
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitForm] = useCreateAccountAndSubmitFormMutation();

  const {
    data: categoriesDB,
    refetch,
    isLoading: isLoadingData,
    error,
  } = useGetQuestionsQuery({}, { refetchOnMountOrArgChange: true });

  const categories =
    categoriesDB?.filter((cat) => cat.questions.length > 0) ?? [];

  if (error) {
    console.error(error);
  }

  const onSubmit = (values: FormikValues) => {
    setIsLoading(true);
    console.log("These are the values", values);

    dispatch(saveAnswer(values));

    setTimeout(() => {
      console.error("Not yet implemented.");
      navigate("../quickscan/account");
      setIsLoading(false);
    }, 1000);
  };

  function getInitValues(labeledValues: Question[]) {
    const init: any = {};

    labeledValues.forEach((val) => {
      init[val.labeledValue.field] = val.labeledValue.initValue;
    });

    const init_vals = Object.assign(init, saved_answers);
    console.log("values", init_vals);
    return init_vals;
  }

  function getValidationValues(labeledValues: Question[]) {
    const yupSchema: any = {};

    labeledValues.forEach((val) => {
      yupSchema[val.labeledValue.field] = yup
        .number()
        .oneOf([0, 1, 2, 3], `Deze vraag is verplicht`)
        .required();
    });

    const schema: yup.AnyObjectSchema = yup.object().shape(yupSchema);

    return schema;
  }

  if (isLoadingData) {
    return (
      <>
        <LinearProgress />
        <Typography>
          Asset Management Quickscan wordt geladen. 1 moment geduld aub.
        </Typography>
        <Typography>Dit kan enkele seconden duren.</Typography>
      </>
    );
  }

  if (!categories) {
    return (
      <>
        <Typography>Kon vragen niet correct laden...</Typography>
        <Button
          onClick={() => {
            refetch();
          }}
        >
          Probeer opnieuw
        </Button>
      </>
    );
  }

  if (isNaN(activeCategory) || activeCategory > categories.length) {
    return <Account />;
  }

  // Je kan óf de <Formik/> component gebruiken, of je kan useFormik gebruiken.
  return (
    <>
      <LinearProgress
        variant="determinate"
        value={((activeCategory + 1) / categories?.length) * 100}
      />

      <Formik
        onSubmit={onSubmit}
        initialValues={getInitValues(
          categories.flatMap((category) => category.questions)
        )}
        validationSchema={getValidationValues(
          categories.flatMap((category) => category.questions)
        )}
      >
        {(props) => {
          const { values } = props;

          console.log("These are the values: ", values);
          dispatch(saveAnswer(values));

          const nextPage = async () => {
            // Before going to the next page, validate the questions.

            const currentQuestions = categories[activeCategory].questions;
            const fields = currentQuestions.map((q) => {
              return q.labeledValue.field;
            });

            await Promise.all(fields.map((f) => props.validateField(f)));

            const currentErrors: any[] = [];
            fields.forEach((f) => {
              const e = props.errors[f];
              if (e) {
                currentErrors.push(e);
              }
            });

            if (currentErrors.length <= 0) {
              navigate(`../quickscan/${activeCategory + 1}`, { replace: true });
              // setActiveCategory(activeCategory + 1);
            } else {
              console.log("current error: ", currentErrors);
            }
          };

          // The props.values is a object {[label]: [value]}
          // To loop over them, we need to obtain an array of the keys.
          const questions = Object.keys(values);
          return (
            <>
              <Box textAlign="center">
                <Typography variant="h4" color="primary">
                  {categories[activeCategory].category}
                </Typography>
              </Box>
              <Form>
                <Divider />
                <List>
                  {categories[activeCategory].questions.map(
                    (question: Question, key: number) => {
                      // const question = props.values[field];
                      const field = question.labeledValue.field;
                      return (
                        <Fragment key={key}>
                          <ListItem key={"mui-form-field-" + field}>
                            <Grid container>
                              <Grid xs={12} sm={8} item px={2} py={1}>
                                <ListItemText>{question.text}</ListItemText>
                              </Grid>

                              <Grid item xs={12} sm={4}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "right",
                                    mr: 2,
                                  }}
                                >
                                  <ButtonGroup color="secondary">
                                    {question.possible_answers.map(
                                      (
                                        possible_value: number,
                                        index: number
                                      ) => {
                                        return (
                                          <Button
                                            color="primary"
                                            variant={
                                              values[field] == possible_value
                                                ? "contained"
                                                : "outlined"
                                            }
                                            key={`field-${field}-${index}`}
                                            onClick={() => {
                                              props.setFieldValue(
                                                field,
                                                possible_value
                                              );
                                            }}
                                          >
                                            {possible_value}
                                          </Button>
                                        );
                                      }
                                    )}
                                  </ButtonGroup>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "right",
                                    mr: 2,
                                  }}
                                >
                                  <Typography color="red" textAlign="end">
                                    <ErrorMessage name={field} />
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider />
                        </Fragment>
                      );
                    }
                  )}
                  <ListItem
                    style={{
                      direction: "rtl",
                    }}
                  >
                    {activeCategory === categories.length - 1 ? (
                      <LoadingButton
                        color="secondary"
                        variant="contained"
                        type="submit"
                        loading={isLoading}
                        style={{
                          color: "white",
                        }}
                      >
                        Opslaan
                      </LoadingButton>
                    ) : (
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                          nextPage();
                        }}
                      >
                        Volgende
                      </Button>
                    )}
                    {activeCategory !== 0 ? (
                      <Box style={{ marginRight: 10 }}>
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={() => {
                            navigate(`../quickscan/${activeCategory - 1}`);
                          }}
                        >
                          Vorige
                        </Button>
                      </Box>
                    ) : null}
                  </ListItem>
                </List>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default FormIPSolutions;

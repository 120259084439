import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: ["sans-serif"].join(","),
  },

  palette: {
    primary: {
      main: "#1e8dce",
    },
    secondary: {
      main: "#f7a600",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        sx: {
          fontWeight: "bold",
          fontFamily: ["Ubuntu", "sans-serif"].join(","),
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            // background: "white",
            borderRadius: "white",
          },
        },
      },
    },
  },
});

export default theme;

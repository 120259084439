import { Button } from "@mui/material";
import React from "react";
import { useCreateQuestionsMutation } from "../redux/api";

// import { Container } from "./styles";

const VragenInkloppen: React.FC = () => {
  const categories = [
    "Asset Integrity & maintenance Plans",
    "Asset performance & health monitoring Plans",
    "Systems engineering & Asset register",
    "Data, document & information management",
    "Technical Standards & Legislation",
    "Organizational structure & People & leadership",
    "Governance, KPI’s & performance management",
    "Operate & Maintain assets",
    "Contractor management",
    "Spare part management",
    "Project Management",
    "Management of Change",
  ];

  const questions = [
    "In welke mate zijn preventieve en inspectieplannen gedefinieerd op basis van een onderhoud strategie, een risico analyse en de wettelijke vereisten ?",
    "In welke mate wordt conditiebewaking toegepast bij kritieke installaties om de technische en integriteitsstatus van installaties te bewaken ?",
    "In welke mate zijn alle Tags en installaties uniek geïdentificeerd en als een up to date as-built register opgenomen in een onderhoudsbeheerssysteem, inclusief een categorisatie in safety critical en business critical ? ",
    "In welke mate is er een adequaat beheer van asset gerelateerde data, informatie, tekeningen en documenten ingericht met een eenduidig eigenaarschap, versiebeheer en een eenduidig en geintegreerd beheer van IT-systemen (EAM, Document management)? ",
    "In welke mate is er een compliance register en zijn er technische standaarden vastgelegd waarin de wettelijke en bedrijfsverplichtingen en minimale richtlijnen zijn opgenomen en zijn gekoppeld aan de aanwezige installatie(types) ?",
    "In welke mate is er een organisatorische structuur en cultuur aanwezig voor het beheren, uitvoeren en optimaliseren van Asset Management in de organisatie en het beheren en optimalisatie van de kennis en kunde in de organisatie ?",
    "In welke mate is er een overleg- en rapportage structuur aanwezig als onderdeel van het totale asset management systeem om de processen en de prestaties te beheersen ?",
    "In welke mate is er een structurele registratie en beheersing van de uitvoerende activiteiten in het veld, inclusief toezichthouderschap?",
    "In welke mate heeft uitbesteding van diensten plaatsgevonden op basis van een risico- en criticaliteitsaanalyse ?",
    "In welke mate is er een reservedelenstrategie beschikbaar en is de voorraad gebaseerd op een vergelijk en analyse van het verbruik, de levertijd, de criticaliteit en de kosten van de reservedelen ?",
    "In welke mate is er een project management procedure gedocumenteerd, gecommuniceerd en zijn medewerkers hierin getraind ?",
    "In welke mate is er een Management of Change procedure gedocumenteerd, goedgekeurd en onderdeel van het kwaliteitssysteem in de organisatie ?",
  ];
  const explainations = [
    "Om de vereiste installatie prestaties te waarborgen dient een onderhoudsstrategie gedefinieerd te worden en dienen onderhouds- en inspectieplannen gedefinieerd te worden in geval van een andere strategie dan breakdownstrategie. De onderhouds- en inspectieplannen dienen gebaseerd te zijn op het compliance registeren (de wet- en regelgeving & bedrijfsvereisten), een risico analyse (FMECA) en de levensduurbenadering van installaties.",
    "Conditiebewaking van installaties is een essentieel onderdeel van de asset management strategie en reliability strategie. Het registreren en bewaken van ontwikkelingen in de installatieconditie kunnen ondersteunen bij het voorspellen van het faalgedrag . Dit kan gebruikt worden om de installatieprestaties verder te verbeteren en de onderhoudsstrategie van de betreffende installaties aan te scherpen. Er dient een inzicht te zijn in de actuele conditie om het faalrisico te kunnen bepalen en daarmee de noodzakelijke vervangingsinvesteringen of levensduurverlengingsinvesteringen te kunnen plannen. ",
    "Voor een adequaat beheer van installaties dienen alle installaties opgenomen te worden in een Asset register. Hierin dient ook de installatie meta data te worden opgenomen, waaronder installatie datum, technische specificaties, type assets en historie. Een risico analyse van de installaties kan gebruikt worden om de installaties te classificeren als veiligheid kritisch of bedrijf kritisch.  ",
    "Up-to-date en volledige  data, informatie, tekeningen en documenten vormen de kern  van een goed beheer van de assets. Dit geldt voor alle levensfasen van een installatie tot en met het analyseren van prestaties en het meten van effecten van wijzigingen. Betrouwbare informatie is tevens essentieel om de technische integriteit en de veiligheid van de omgeving te waarborgen.",
    "Er dient een up-to-date en volledig compliance register te zijn waarin alle van toepassing zijnde wet- en regelgeving en bedrijfsvereisten en richtlijnen zijn opgenomen. Het eigenaarschap voor het beheer van het compliance register dient eenduidig te zijn belegd. Het compliance register dient  periodiek getoetst te worden op updates en wijzigingen en op basis van de wijzigingen dient een impact analyse uitgevoerd te worden op het onderhoudsprogramma.  In het compliance register dient een verwijzing naar onderhouds- en operationele activiteiten te zijn opgenomen om de compliance te kunnen toetsen. ",
    "De organisatie dient de vereiste bekendheid met, de kennis, kunde en competenties op het gebied van asset management op alle lagen van de organisatie in kaart te brengen. Op basis hiervan kan een trainings- en opleidingsplan worden opgesteld om de kennis, kunde en competenties systematisch te vergroten en te onderhouden. Door het registreren en bijhouden van gegeven opleidingen kunnen de competenties bijgehouden worden. ",
    "Er dient een overleg- en rapportage structuur te zijn gedefinieerd voor het sturen en bewaken van de asset management processen en realisatie van de doelen. De overleg- en rapportage structuur dient gebaseerd te zijn op een doorvertaling van jaardoelen naar kwartaal, maand en weekdoelen. Prestatie indicatoren dienen gedefinieerd te zijn ter ondersteuning van de strategie en doelbewaking. ",
    "Om de totale werklast van de onderhoudsdienst te kunnen beheersen dienen alle onderhoudsactiviteiten geregistreerd te worden als werkaanvragen/werkorders en dient een onderhoudsprocedure geïmplementeerd te zijn om de levensloop van werkorders te volgen: ie het aanvragen, beoordelen en goedkeuren, voorbereiden, plannen, uitvoeren en evalueren van werkorders. Een eenduidige prioriteitstelling en beoordelingscriteria ondersteunen een efficiënt verloop van het proces. Op basis van een grondige registratie kan de planningsgraad en planning compliance gemeten worden. ",
    "Uitbesteding van activiteiten is onlosmakelijk verbonden met onderhoudsactiviteiten. Uitbesteding kan op verschillende niveaus plaatsvinden, variërend van alleen de uitvoering op basis urenverrekening, uitbesteding van volledige werkpakketten (voorbereiding en uitvoering), uitbesteding op basis van een afgesproken prestatie of uitbesteding gebaseerd op een volledig uitbestedingscontract. Veelal zal echter een combinatie van deze vormen aanwezig zijn, afhankelijk van het soort installaties, de risico's de competenties en de marktbeschikbaarheid van de diensten. Het is van belang vooraf vast te stellen welke mate en welke vorm van uitbesteding gewenst is. ",
    "Kritische reservedelen dienen bepaald te zijn op basis van een risico analyse op falen, de standaard levertijd en markt beschikbaarheid van de reservedelen. De voorraadstrategie voor de betreffende reservedelen dient gebaseerd te zijn op deze analyse (op voorraadleggen, op consignatie leggen, bestellen wanneer nodig). ",
    "Om de totale werklast van de onderhoudsdienst te kunnen beheersen dienen alle onderhoudsactiviteiten geregistreerd te worden als werkaanvragen/werkorders en dient een onderhoudsprocedure geïmplementeerd te zijn om de levensloop van werkorders te volgen: ie het aanvragen, beoordelen en goedkeuren, voorbereiden, plannen, uitvoeren en evalueren van werkorders. Een eenduidige prioriteitstelling en beoordelingscriteria ondersteunen een efficiënt verloop van het proces. Op basis van een grondige registratie kan de planningsgraad en planning compliance gemeten worden. ",
    "Het is van essentieel belang dat de impact van alle wijzigingen aan het asset management systeem en/or de asset management organisatie wordt begrepen voordat deze worden doorgevoerd. Hiervoor dienen de risico's van de geplande wijzigingen in kaart te worden gebracht en te worden beoordeeld. Hiervoor dient een formele Management of Change proces aanwezig te zijn als onderdeel van het kwaliteitssysteem. De organisatie dient getraind te zijn in het MoC proces en deze dient systematisch te worden toegepast.",
  ];

  const [mutation] = useCreateQuestionsMutation();

  return (
    <Button
      onClick={async () => {
        const objects: {
          name: string;
          order: number;
          questions: {
            data: [{ order: 0; explaination: string; question: string }];
          };
        }[] = categories.map((cat, index) => {
          return {
            name: cat,
            order: index + 5,
            questions: {
              data: [
                {
                  order: 0,
                  explaination: explainations[index],
                  question: explainations[index],
                },
              ],
            },
          };
        });

        const res = await mutation(objects);
        console.log(res);
      }}
    >
      Maak de vragen aan
    </Button>
  );
};

export default VragenInkloppen;

import { ListItem, List, TextFieldProps, Grid, TextField } from "@mui/material";
import { FormikConfig, FormikValues, useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import ErrorMessage from "./ErrorMessage";
import { ReactNode } from "react";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
type FormikCustonConfig = PartialBy<
  FormikConfig<FormikValues>,
  "initialValues"
>;

export interface LabelInterface {
  label: string;
  field: string;
  schema: Yup.AnySchema;
  initValue: any;
  type: string;
}

export function getInitValues(labeledValues: LabelInterface[]) {
  const init: any = {};

  labeledValues.forEach((val) => {
    init[val.field] = val.initValue;
  });
  return init;
}

export function getValidationValues(labeledValues: LabelInterface[]) {
  const yupSchema: any = {};

  labeledValues.forEach((val) => {
    yupSchema[val.field] = val.schema;
  });

  const schema: Yup.AnyObjectSchema = Yup.object().shape(yupSchema);

  return schema;
}

/**
 * Create a form. Combines Formik and MUI and Yup
 * @author Ivo Chen
 * @param param0
 * @returns
 */
function FormikMuiForm({
  disabled,
  labeledValues,
  formik: { onSubmit, ...restFormik },
  inputProps,
  submitButtonText,
  isLoading,
  component,
  errorMessage,
}: {
  disabled?: boolean;
  isLoading?: boolean;
  labeledValues: LabelInterface[];
  formik: FormikCustonConfig;
  inputProps?: TextFieldProps;
  submitButtonText: string;
  errorMessage?: string;
  component?: ReactNode;
}) {
  const formik = useFormik({
    initialValues: getInitValues(labeledValues),
    validationSchema: getValidationValues(labeledValues),
    onSubmit,

    ...restFormik,
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!disabled) {
          formik.handleSubmit();
        }
      }}
    >
      <List>
        {labeledValues.map((value, key) => {
          const field = value.field;
          return (
            <ListItem key={"mui-form-field-" + field}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <TextField
                    type={value.type}
                    key={key}
                    id={field}
                    label={value.label}
                    name={field}
                    onBlur={formik.handleBlur}
                    value={formik.values[field]}
                    onChange={formik.handleChange}
                    error={
                      formik?.touched[field] && Boolean(formik?.errors[field])
                    }
                    // @ts-ignore
                    helperText={formik?.touched[field] && formik?.errors[field]}
                    {...inputProps}
                  />
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
        <Grid xs={12}>{component ? component : null}</Grid>
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <ListItem>
          <LoadingButton
            size="large"
            fullWidth
            variant="contained"
            type="submit"
            loading={isLoading}
            disabled={disabled}
          >
            {submitButtonText}
          </LoadingButton>
        </ListItem>
      </List>
    </form>
  );
}

export function createLabel(
  label: string,
  field: string,
  schema: Yup.AnySchema,
  initValue: any,
  type?: string
): LabelInterface {
  if (!type) {
    type = "";
  }

  return { label, field, schema, initValue, type };
}

export default FormikMuiForm;

import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Container,
} from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import IPLogo from "../assets/ip_logo.png";

const Header: React.FC = () => {
  const location = useLocation();
  console.log("location", window.location.host);
  console.log("VERSION: 3");
  const newUrl = `https://ip-solutions-website.netlify.app${window.location.pathname}`;

  const shouldRedirect = () => {
    return (
      process.env.NODE_ENV === "production" &&
      window.location.host !== "ip-solutions-website.netlify.app"
    );
  };

  useEffect(() => {
    if (shouldRedirect()) {
      console.log("Redirecting to ", newUrl);
      window.location.href = newUrl;
    }
  }, []);

  if (shouldRedirect()) {
    return (
      <Container>
        <Typography>
          U wordt doorverwezen naar een nieuwe website....
        </Typography>
        <Button
          onClick={() => {
            window.location.href = newUrl;
          }}
        >
          Ga direct
        </Button>
      </Container>
    );
  }
  return (
    <Grid container spacing={1} display="flex" justifyContent="center">
      <Grid item textAlign={"center"} xs={12} sm={8} md={6}>
        <img src={IPLogo} style={{ width: "100%" }} />
      </Grid>
      <Outlet />
    </Grid>
  );
};

export default Header;

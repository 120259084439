import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  styled,
  ButtonGroup,
} from "@mui/material";
import ChartComp from "./Chart";
import { ChartData } from "chart.js";
import SubTitle from "./SubTitle";
import Title from "./Title";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import {
  useGetAnswersQuery,
  useGetGroupAverageAnswersQuery,
  useVerifyEmailMutation,
} from "../redux/api";
import "./ResultsPageCss.css";
import IPLogo from "../assets/ip_logo.png";

const TypographyBold = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.primary.main,
}));

const TypographyUnderlined = styled("span")(({ theme }) => ({
  textDecoration: "underline",
}));

const opacity = 1;

const colourRange = [
  "pink",
  "#FAC003", // yellow
  "#3575c7", // blue
  "purple", // purple
  "#5FAE3d", // green
  "#fA7523", // orange

  // "rgba(133, 36, 172, " + opacity + ")",
  // "rgba(143, 170, 220, " + opacity + ")",
  // "rgba(112, 173, 71, " + opacity + ")",
  // "rgba(255, 192, 0, " + opacity + ")",
  // "rgba(244, 177, 131, " + opacity + ")",

  ,
  ,
];

const empolyee_intervals = [10, 25, 50, 500000000];
const maintenance_intervals = [500000, 1000000, 5000000, 500000000];

function ResultsPage() {
  const printRef = useRef();
  const { id } = useParams();
  const [empolyeeInterval, setEmployeeInterval] = useState<{
    lte: number;
    gte: number;
  }>({ lte: 0, gte: 0 });
  const [maintenanceInterval, setMaintenanceInterval] = useState<{
    lte: number;
    gte: number;
  }>({ lte: 0, gte: 0 });
  const [companyName, setCompanyName] = useState("");
  const [groupAverage, setGroupAverage] = useState(0.01);
  const results = ["Developing", "Basic", "Professional", "Excellence"];

  const getInterval = (point: number, intervals: number[]) => {
    const lte_index = intervals.filter((v) => {
      return v < point;
    }).length;

    if (lte_index === 0) {
      return {
        lte: intervals[0],
        gte: 0,
      };
    } else {
      return {
        lte: intervals[lte_index],
        gte: intervals[lte_index - 1],
      };
    }
  };

  const {
    data: group_average,
    refetch,
    error: error_2,
  } = useGetGroupAverageAnswersQuery({
    num_empolyee_gte: empolyeeInterval.gte,
    num_empolyee_lte: empolyeeInterval.lte,
    maintenance_budget_gte: maintenanceInterval.gte,
    maintenance_budget_lte: maintenanceInterval.lte,
  });

  const { data, error, isLoading } = useGetAnswersQuery({
    customer_id: id ?? "",
  });
  let dataset_1: {
    label: string;
    answer: number;
    category: string;
    categoryOrder: number;
    question: string;
    all: any;
  }[] = [];

  console.log("answers error? ", error);

  let dataset_2: {
    label: string;
    score: number;
  }[] = [
    { label: companyName ? companyName : "Uw bedrijf", score: 0 },
    { label: "Avg Totaal", score: 0 },
    {
      label: "Avg Groep",
      score: groupAverage,
    },
  ];
  const [verifyEmail] = useVerifyEmailMutation();

  useEffect(() => {
    if (data) {
      // @ts-expect-error
      const { num_empolyees, num_maintenance_budget, company_name } =
        data.customers_by_pk;

      if (num_empolyees !== undefined && num_maintenance_budget !== undefined) {
        setEmployeeInterval(getInterval(num_empolyees, empolyee_intervals));
        setMaintenanceInterval(
          getInterval(num_maintenance_budget, maintenance_intervals)
        );

        refetch();
      }

      if (
        groupAverage === 0.01 &&
        group_average?.answers_aggregate.aggregate?.avg?.answer
      ) {
        setGroupAverage(
          group_average?.answers_aggregate.aggregate?.avg?.answer
        );
      }
    }

    if (id) {
      verifyEmail({ customer_id: id }).catch(() => {
        console.error("Customer email NOT validated");
      });
    }
  }, [group_average]);

  console.log("Answers", data?.answers);
  if (data) {
    // @ts-expect-error
    const { company_name } = data.customers_by_pk;
    if (!companyName && company_name) {
      setCompanyName(company_name);
    }
    // @ts-expect-error
    dataset_1 = data.answers
      .filter((a) => {
        return a.answer !== undefined && a.answer !== null;
      })
      .map((a) => {
        return {
          question: a.question.question,
          answer: a.answer,
          label: a.question.element_sub,
          category: a.question.category?.name,
          categoryOrder: a.question.category?.order,
          all: a.question.category,
        };
      });

    dataset_2[0].score =
      data.answers.reduce((a, b) => {
        if (b.answer && !isNaN(Number(b.answer))) {
          return a + Number(b.answer);
        }
        return a;
      }, 0) / data.answers.length;

    // alert(JSON.stringify(data.answers_aggregate.aggregate?.avg));
    if (!isNaN(Number(data.answers_aggregate.aggregate?.avg?.answer))) {
      dataset_2[1].score = Number(
        data.answers_aggregate.aggregate?.avg?.answer
      );
    }
  }

  const handlePrint = useReactToPrint({
    // @ts-expect-error
    content: () => printRef?.current,
  });

  const chart1Data: ChartData<"bar"> = {
    labels: dataset_1.map((d) => `${d.label}`),
    datasets: [
      {
        label: "Score",
        data: dataset_1.map((d) => d.answer),
        backgroundColor: dataset_1.map((a, index) => {
          console.log(
            "Taking cat order, cat name, color lange",
            a.all,
            a.categoryOrder,
            a.category,

            colourRange.length,
            a.categoryOrder % colourRange.length
          );
          return colourRange[a.categoryOrder % colourRange.length];
        }),
        borderColor: dataset_1.map((a) => {
          return colourRange[a.categoryOrder % colourRange.length];
        }),
        borderWidth: 1,
      },
    ],
  };
  const chart2Data: ChartData<"bar"> = {
    labels: dataset_2.map((d) => d.label),
    datasets: [
      {
        label: "Score",
        data: dataset_2.map((d) => d.score),
        backgroundColor: colourRange,
        borderColor: colourRange,
        borderWidth: 1,
        maxBarThickness: 50,
      },
    ],
  };

  if (!id) {
    return <>Ongeldige credentials</>;
  }

  console.log("This is the data", data);

  return (
    <Container>
      {/* <Button onClick={handlePrint}>Print naar PDF</Button> */}
      <Box ref={printRef}>
        <div id="content">
          <div id="pageFooter">
            <table>
              <thead className="report-header">
                <tr>
                  <th className="report-header-cell">
                    <div className="header-info">
                      <Grid
                        container
                        spacing={1}
                        display="flex"
                        justifyContent="center"
                      >
                        <Grid item textAlign={"center"} xs={12} sm={8} md={6}>
                          <img src={IPLogo} style={{ width: "50%" }} />
                        </Grid>
                      </Grid>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <Grid container spacing={1}>
                  <Grid xs={12} item textAlign="center">
                    <Title>Resultaten Asset Management Quickscan</Title>
                  </Grid>
                  <Grid xs={12} item>
                    <CardContent>
                      <Typography gutterBottom>
                        <TypographyBold>
                          Beste {data?.customers_by_pk?.person_name},
                        </TypographyBold>
                      </Typography>
                      <Typography gutterBottom>
                        Voor u ligt het resultaat van de invulde online
                        quickscan. De quickscan is gebaseerd op het Asset
                        Management model van IP-Solutions. Het doel van de
                        quickscan is een eerste indruk geven van het
                        volwassenheidsniveau van uw organisatie.{" "}
                      </Typography>
                      <Typography gutterBottom>
                        Onderstaand treft u de resultaten aan van de quickscan.
                        De resultaten zijn weergegeven in een staafdiagram
                        waarbij u aan de linkerzijde het volwassenheidsniveau
                        ziet. De resultaten zijn op kleur gegroepeerd waarbij
                        elke kleur een Asset Management sectie vormt. De Asset
                        Management secties vormen samen het Asset Management
                        model.
                      </Typography>
                    </CardContent>
                  </Grid>
                  <Grid xs={12} item textAlign="center">
                    <Card>
                      <CardContent style={{ paddingBottom: 0 }}>
                        <SubTitle>Uw score is:</SubTitle>
                        <Title>
                          {
                            results[
                              Math.round(
                                dataset_1.reduce((a, b) => {
                                  return a + b.answer;
                                }, 0) / dataset_1.length
                              ) - 1
                            ]
                          }
                        </Title>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid xs={12} item>
                    <CardContent>
                      {/* <Box my={5} paddingX={8} paddingY={5}> */}
                      <ChartComp
                        dataSet={chart1Data}
                        title="Average Maturity level Asset Integrity"
                      />
                      {/* </Box> */}
                    </CardContent>
                  </Grid>
                  {/* <Grid xs={12} item>
            <Card>
              <CardContent>
   
                <ChartComp
                  dataSet={chart2Data}
                  title="Average Maturity level Asset Integrity  - Benchmark"
                />
  
              </CardContent>
            </Card>
          </Grid> */}
                  <Grid item xs={12}>
                    <CardContent>
                      <Typography>
                        De volwassenheidsniveaus zijn gebaseerd op de volgende
                        indeling.
                      </Typography>
                      <Box textAlign="center">
                        <img
                          src={require("../assets/volwassenheid.png")}
                          style={{
                            width: "100%",
                            maxWidth: 850,
                          }}
                        />
                      </Box>
                      <Typography>
                        Vervolgens wordt een toelichting gegeven over het
                        IP-Solutions Asset Management model.
                      </Typography>
                    </CardContent>
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <CardContent>
                    <Title>Het IP-Solutions Asset Management model</Title>
                    <Typography>
                      Het IP-Solutions Asset Management model is een procesmodel
                      met als fundatie het ISO 55000 – Asset Management
                      Framework. Het Asset Management model is gericht op een
                      beheersing en optimalisatie van de installatie-levensduur.
                      Het model is verdeeld in 5 hoofdsecties welke allen
                      fundamentele bouwblokken vormen van Asset Management. Het
                      Asset Management model vormt in het geheel een continue
                      verbeterloop (P-D-C-A loop) met het stellen van doelen,
                      het maken van plannen, het uitvoeren van plannen en het
                      opvolgen van activiteiten
                    </Typography>
                    <Box textAlign="center">
                      <img
                        style={{
                          width: "100%",
                          maxWidth: 850,
                        }}
                        src={require("../assets/assetmanagement.png")}
                      />
                    </Box>
                    <Typography gutterBottom>
                      De volgende Asset Management secties worden onderkend in
                      het Asset Management model:
                    </Typography>

                    <TypographyBold>
                      Asset Management Policy & strategy
                    </TypographyBold>
                    <Typography gutterBottom>
                      In deze sectie wordt de Line of Sight voor Asset Management
                      vastgelegd als leidraad voor de organisatie. Op basis van
                      de bedrijfsdoelen en bedrijfsstrategie worden een
                      vertaling gemaakt – meestal voor een periode van meerdere
                      jaren - naar de eisen aan de installaties op het gebied
                      van beschikbaarheid, betrouwbaarheid, duurzaamheid en
                      noodzakelijke aanpassingen en totale levensduur-kosten.
                      Deze worden vertaald naar een{" "}
                      <TypographyUnderlined>
                        Strategisch Asset Management Plan
                      </TypographyUnderlined>{" "}
                      als routekaart om duurzaam bij te dragen aan de
                      bedrijfsdoelstellingen en concrete{" "}
                      <TypographyUnderlined>
                        Asset Management doelstellingen
                      </TypographyUnderlined>{" "}
                      als meetpunt voor de effectiviteit van de strategie. In
                      lijn met overige beleidsdomeinen in de organisatie wordt
                      het{" "}
                      <TypographyUnderlined>
                        Asset Management beleid
                      </TypographyUnderlined>{" "}
                      gedefinieerd of getoetst om richting te geven aan
                      beslissingen op het gebied van Asset Management. Typische
                      beleidsthema's gaan over ambitieniveau, organisatie &
                      kennisontwikkeling, in- versus uitbesteding, reservedelen
                      & voorraad, investeringen versus onderhoud,
                      onderhoudsstrategie, besturing en transparantie.
                    </Typography>

                    <TypographyBold>Organizational Performance</TypographyBold>
                    <Typography gutterBottom>
                      In deze sectie wordt de effectiviteit van de organisatie
                      bepaald. Dit bestaat uit de wijze waarop de{" "}
                      <TypographyUnderlined>
                        organisatiestructuur & bemensing
                      </TypographyUnderlined>{" "}
                      (rollen, verantwoordelijkheden & bevoegdheden, vereiste
                      kennis & competenties, leiderschap) wordt vormgegeven.
                      Naar de organisatie zal ook de{" "}
                      <TypographyUnderlined>
                        aansturing van de organisatie
                      </TypographyUnderlined>{" "}
                      en processen (besturingsmodel, prestatie indicatoren,
                      afdelingsdoelen) worden ingericht.
                    </Typography>

                    <TypographyBold>
                      AM information & data control
                    </TypographyBold>
                    <Typography gutterBottom>
                      In deze sectie wordt de beschikbaarheid, transparantie en
                      traceerbaarheid van data gedefinieerd. Een compleet en
                      up-to-date inzicht in de installaties vormt het{" "}
                      <TypographyUnderlined>
                        asset register
                      </TypographyUnderlined>
                      . Een Management of Change proces voor het up-to-date
                      houden van je asset register is essentieel voor de
                      waarborging van integriteit en beheersing van installatie
                      prestaties. Eigenaarschap en versiebeheer van{" "}
                      <TypographyUnderlined>
                        data, informatie en documenten in beheersystemen
                      </TypographyUnderlined>
                      biedt de basis voor betrouwbare en daarmee bruikbare
                      informatie. Een normering van technische eisen uit
                      technische standaarden en wet- en regelgeving
                      vereenvoudigen en waarborgen de technische integriteit en
                      prestaties van installaties.
                    </Typography>

                    <TypographyBold>
                      Reliable Assets & Operations
                    </TypographyBold>
                    <Typography gutterBottom>
                      In deze sectie wordt de effectiviteit van asset plannen
                      gedefinieerd. Investerings- en sustaining capex plannen
                      worden vastgelegd in{" "}
                      <TypographyUnderlined>
                        Lange Termijn Installatieplannen
                      </TypographyUnderlined>{" "}
                      en worden jaarlijks getoetst aan toepasselijkheid. De
                      basis voor de installatieperformance wordt gevormd door de{" "}
                      <TypographyUnderlined>
                        integriteits- en asset plannen
                      </TypographyUnderlined>{" "}
                      waarin de periodieke inspectie- en beheersactiviteiten
                      worden uitgevoerd voor het bewaken en waarborgen van
                      prestaties. Door de{" "}
                      <TypographyUnderlined>
                        installatiestatus en installatiegezondheid
                      </TypographyUnderlined>{" "}
                      te meten wordt de effectiviteit van de plannen bewaakt en
                      waar nodig bijgesteld.
                    </Typography>

                    <TypographyBold>Operate & execute</TypographyBold>
                    <Typography gutterBottom>
                      in deze sectie wordt de efficiëntie van de uitvoerende
                      processen gedefinieerd. Dit bestaat het volledige spectrum
                      van het gebruik en onderhouden van installaties tot het
                      uitvoeren van projecten.
                    </Typography>

                    <Title>Routekaart voor de toekomst</Title>
                    <Typography gutterBottom>
                      Op basis van de quickscan en uw ambitieniveau kunt u een
                      routekaart voor Asset Management in uw organisatie
                      vaststellen. Als onderdeel van de uitgebreide Asset
                      Management scan heeft IP-Solutions voor elke sectie een
                      aantal criteria per volwassenheidsniveau gedefinieerd.
                      Deze kan dienen als routekaart en actieplan om het
                      gewenste ambitieniveau te bereiken. Onderstaand ziet u een
                      voorbeeld van de criteria voor sectie Policy & Strategy.
                    </Typography>
                    <Box textAlign="center">
                      <img
                        style={{ width: "100%" }}
                        src={require("../assets/routekaart.png")}
                      />
                      <a href={require("../assets/routekaart.png")} download>
                        Download de routekaart
                      </a>
                    </Box>
                  </CardContent>

                  <CardContent>
                    <Title>Uw antwoorden</Title>
                    <Typography gutterBottom>
                      Onderstaand treft u de antwoorden aan op de vragen uit de
                      quickscan
                    </Typography>
                    <Table>
                      {/* <TableHead>
                <TableCell>Vraag</TableCell>
                <TableCell>Uw antwoord</TableCell>
              </TableHead> */}
                      {/* {dataset_1.map((d) => {
                return <div>{d.category}</div>;
              })} */}
                      {dataset_1.map((d, index) => {
                        return (
                          <>
                            {index > 0 ? (
                              dataset_1[index - 1].category !== d.category ? (
                                <CategoryTitle>{d.category}</CategoryTitle>
                              ) : null
                            ) : (
                              <CategoryTitle>{d.category}</CategoryTitle>
                            )}

                            <TableBody>
                              <TableCell>{d.question}</TableCell>

                              <TableCell>
                                <ButtonChoice answer={d.answer} />
                              </TableCell>
                            </TableBody>
                          </>
                        );
                      })}
                    </Table>
                    <Box style={{ marginTop: 10 }} textAlign={"center"}>
                      <Button onClick={handlePrint}>Print naar PDF</Button>
                    </Box>
                  </CardContent>
                </Grid>
              </tbody>
              <tfoot className="report-footer">
                <tr>
                  <td className="report-footer-cell">
                    <div className="footer-info">
                      <div className="page-footer">
                        <Typography>IP-Solutions B.V.</Typography>
                        <Typography>
                          Van der Takstraat 8 3071 LL Rotterdam
                        </Typography>
                        <Typography>Tel: +31 (0)88 460 01 00</Typography>
                        <Typography>Web: https://ip-solutions.nl</Typography>
                        <Typography>E-mail: info@ip-solutions.nl</Typography>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
            <div className="page-footer-no-print">
              <Typography>IP-Solutions B.V.</Typography>
              <Typography>Van der Takstraat 8 3071 LL Rotterdam</Typography>
              <Typography>Tel: +31 (0)88 460 01 00</Typography>
              <Typography>Web: https://ip-solutions.nl</Typography>
              <Typography>E-mail: info@ip-solutions.nl</Typography>
            </div>
          </div>
        </div>
      </Box>
    </Container>
  );
}

function CategoryTitle({ children }: { children: ReactNode }) {
  return (
    <Box textAlign="center">
      <Typography style={{ marginTop: 10 }} variant="h5" color="primary">
        {children}
      </Typography>
    </Box>
  );
}

function ButtonChoice({ answer }: { answer: number }) {
  return (
    <ButtonGroup color="primary">
      {[0, 1, 2, 3].map((i) => {
        return (
          <Button variant={answer === i ? "contained" : "outlined"}>{i}</Button>
        );
      })}
    </ButtonGroup>
  );
}

export default ResultsPage;

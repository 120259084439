import { createApi } from "@reduxjs/toolkit/query/react";
import { gql } from "graphql-request";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import Question from "../classes/question";
import {
  InsertAnswersMutationMutation,
  AnswerQueryQuery,
  CategoryAndQuestionsQueryQuery,
  Answers_Insert_Input,
  CreateAccountAndSubmitFormMutationMutation,
  AverageAnswerQueryQuery,
  AllCustomersQueryQuery,
  AnswersAdminPanelQueryQuery,
} from "../graphqlTypes";

export class Category {
  constructor(public category: string, public questions: Question[]) {}
}

export const quickscanApi = createApi({
  reducerPath: "quickscan",
  baseQuery: graphqlRequestBaseQuery({
    url: `${process.env.REACT_APP_GRAPHQL_URL}`,
    prepareHeaders: (headers: any, { getState }: { getState: any }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = localStorage.getItem("jwt");

      if (token) {
        headers.set("x-hasura-admin-secret", `${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    submitFormAnswers: builder.mutation<
      InsertAnswersMutationMutation,
      { answers: Question[] }
    >({
      query: ({ answers }) => ({
        document: gql`
          mutation InsertAnswersMutation(
            $objects: [answers_insert_input!] = {}
          ) {
            insert_answers(objects: $objects) {
              affected_rows
            }
          }
        `,
        variables: {
          answers,
        },
      }),
    }),
    getGroupAverageAnswers: builder.query<
      AverageAnswerQueryQuery,
      {
        num_empolyee_gte: number;
        num_empolyee_lte: number;
        maintenance_budget_gte: number;
        maintenance_budget_lte: number;
      }
    >({
      query: ({
        num_empolyee_gte,
        num_empolyee_lte,
        maintenance_budget_gte,
        maintenance_budget_lte,
      }) => {
        return {
          document: gql`
            query AverageAnswerQuery(
              $num_empolyee_gte: Int
              $num_empolyee_lte: Int
              $maintenance_budget_gte: Int
              $maintenance_budget_lte: Int
            ) {
              answers_aggregate(
                where: {
                  customer: {
                    num_empolyees: {
                      _gte: $num_empolyee_gte
                      _lte: $num_empolyee_lte
                    }
                    num_maintenance_budget: {
                      _gte: $maintenance_budget_gte
                      _lte: $maintenance_budget_lte
                    }
                  }
                }
              ) {
                aggregate {
                  avg {
                    answer
                  }
                }
              }
            }
          `,
          variables: {
            num_empolyee_gte,
            num_empolyee_lte,
            maintenance_budget_gte,
            maintenance_budget_lte,
          },
        };
      },
    }),
    answersAdminPanel: builder.query<AnswersAdminPanelQueryQuery, any>({
      query: ({}) => ({
        document: gql`
          query AnswersAdminPanelQuery {
            customers {
              email
              person_name
              company_name
              id
              phone_numbers
              num_empolyees
              num_maintenance_budget
              answers {
                created_at
                question {
                  category_name
                  question
                }
                answer
              }
            }
          }
        `,
        variables: {},
      }),
    }),
    queryAllCustomers: builder.query<AllCustomersQueryQuery, {}>({
      query: ({}) => ({
        document: gql`
          query AllCustomersQuery {
            customers {
              email
              person_name
              company_name
              id
              created_at
            }
          }
        `,
        variables: {},
      }),
    }),
    getAnswers: builder.query<AnswerQueryQuery, { customer_id: string }>({
      query: ({ customer_id }) => {
        console.log("querying getAnswers");
        return {
          document: gql`
            query AnswerQuery($customer_id: uuid!) {
              answers(
                where: { customer_id: { _eq: $customer_id } }
                order_by: { question: { category: { order: asc } } }
              ) {
                customer_id
                question_id

                answer
                question {
                  question
                  element_sub
                  category {
                    name
                    order
                  }
                }
              }
              answers_aggregate {
                aggregate {
                  avg {
                    answer
                  }
                }
              }
              customers_by_pk(id: $customer_id) {
                num_empolyees
                num_maintenance_budget
                person_name
              }
            }
          `,
          variables: {
            customer_id,
          },
        };
      },
    }),
    createAccountAndSubmitForm: builder.mutation<
      CreateAccountAndSubmitFormMutationMutation,
      {
        company_name: string;
        num_empolyees: BigInt;
        num_maintenance_budget: BigInt;
        person_name: string;
        phone_numbers: string;
        answers: Answers_Insert_Input[];
        email: string;
      }
    >({
      query: ({
        company_name,
        num_empolyees,
        num_maintenance_budget,
        person_name,
        phone_numbers,
        answers,
        email,
      }) => {
        console.log(answers);
        console.log(answers);
        return {
          document: gql`
            mutation CreateAccountAndSubmitFormMutation(
              $company_name: String!
              $num_empolyees: Int!
              $num_maintenance_budget: Int!
              $person_name: String!
              $phone_numbers: String
              $answers: [answers_insert_input!]!
              $email: String!
            ) {
              insert_customers_one(
                object: {
                  agree_privacy_statement: "now()"
                  company_name: $company_name
                  num_empolyees: $num_empolyees
                  num_maintenance_budget: $num_maintenance_budget
                  person_name: $person_name
                  phone_numbers: $phone_numbers
                  answers: { data: $answers }
                  email: $email
                }
              ) {
                id
              }
            }
          `,
          variables: {
            company_name: String(company_name),
            num_empolyees: Number(num_empolyees),
            num_maintenance_budget: Number(num_maintenance_budget),
            person_name: String(person_name),
            phone_numbers: String(phone_numbers),
            answers,
            email,
          },
        };
      },
    }),
    getQuestions: builder.query<Category[], {}>({
      keepUnusedDataFor: 0,
      query: () => ({
        document: gql`
          query CategoryAndQuestionsQuery {
            categories(order_by: { order: asc }) {
              questions(order_by: { order: asc }) {
                id
                question
                explaination
              }
              name
            }
          }
        `,
        variables: {},
      }),
      transformResponse: (response: CategoryAndQuestionsQueryQuery) => {
        return response.categories.map((cat) => {
          const questions = cat.questions.map((q) => {
            return new Question(q.question, q.id, q.explaination, [0, 1, 2, 3]);
          });
          return new Category(cat.name, questions);
        });
      },
    }),
    createQuestions: builder.mutation<
      any,
      {
        name: string;
        order: number;
        questions: {
          data: [{ order: 0; explaination: string; question: string }];
        };
      }[]
    >({
      query: (objects) => ({
        document: gql`
          mutation CreateQuestionsInBulk(
            $objects: [categories_insert_input!]!
          ) {
            insert_categories(objects: $objects) {
              affected_rows
            }
          }
        `,
        variables: {
          objects,
        },
      }),
    }),
    verifyEmail: builder.mutation<any, { customer_id: string }>({
      query: ({ customer_id }) => ({
        document: gql`
          mutation MyMutation($id: uuid!) {
            update_customers_by_pk(
              pk_columns: { id: $id }
              _set: { verified: true }
            ) {
              verified
              id
            }
          }
        `,
        variables: { id: customer_id },
      }),
    }),
  }),
});

export const {
  useSubmitFormAnswersMutation,
  useCreateAccountAndSubmitFormMutation,
  useGetAnswersQuery,
  useGetQuestionsQuery,
  useGetGroupAverageAnswersQuery,
  useVerifyEmailMutation,
  useCreateQuestionsMutation,
  useQueryAllCustomersQuery,
  useAnswersAdminPanelQuery,
} = quickscanApi;

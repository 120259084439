import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  ListItem,
  Typography,
  List,
  Grid,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetQuestionsQuery } from "../redux/api";
import Title from "./Title";

// import { Container } from './styles';

const Welcome: React.FC = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const [checked, setChecked] = useState(false);

  const {
    data: categoriesDB,
    refetch,
    isLoading: isLoadingData,
    error,
  } = useGetQuestionsQuery({});

  const questions_num = categoriesDB?.map((c) => c.questions).length ?? 15;

  return (
    <Container>
      <Card
        style={{
          backgroundColor: "#1e8dce",
          color: "white",
        }}
      >
        <CardContent>
          <Title textAlign="center">Asset Management Quickscan</Title>

          {/* <Typography gutterBottom textAlign="center">
            Welkom bij de
            <span style={{ fontWeight: "bold" }}> gratis </span>
            Asset Management Quickscan
          </Typography> */}

          <CardContent>
            <Typography>
              Leuk dat u de scan doet. De scan bevat {questions_num} vragen en
              duurt ongeveer {Math.round((questions_num * 10) / 60)} minuten. Na
              afloop ontvangt u een email met de resultaten.
            </Typography>

            <Typography>Per vraag kunt u het volgende aangeven:</Typography>
          </CardContent>

          <List>
            {[
              "0 = niet, volledig afwezig",
              "1 = delen aanwezig, maar wordt niet structureel toegepast",
              "2 = voor het merendeel aanwezig maar niet actief gebruikt",
              "3 = wordt volledig toegepast en wordt periodiek verbeterd",
            ].map((text) => {
              return <ListItem>{text}</ListItem>;
            })}
          </List>

          <Typography variant="subtitle1" textAlign="center">
            Akkoord algemene&nbsp;
            <a href="https://ipsolutionsdv.wpengine.com/privacy-statement/">
              privacy statement
            </a>
            <Checkbox
              style={{
                color: "white",
              }}
              onClick={() => {
                setChecked(!checked);
              }}
              checked={checked}
            />
          </Typography>
          <Box textAlign="center">
            <Button
              disabled={!checked}
              variant="contained"
              color="secondary"
              onClick={() => {
                navigate("/quickscan");
              }}
            >
              Start de vragenlijst
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Welcome;

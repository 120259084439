import { Typography } from "@mui/material";
import React, { ReactNode } from "react";

// import { Container } from './styles';

const Title = ({
  children,
  textAlign,
  ...rest
}: {
  textAlign?: "center" | "end" | "start";
  children: ReactNode;
}) => {
  return (
    <Typography
      gutterBottom
      variant="h4"
      color="secondary"
      style={{
        fontWeight: "bold",
        textAlign,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default Title;

import {
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  Chip,
} from "@mui/material";

import React from "react";
import { useParams } from "react-router-dom";
import Title from "./Title";

// import { Container } from './styles';

const ThankYou: React.FC = () => {
  const { reference } = useParams();
  return (
    <Container>
      <Grid xs={12} item textAlign={"center"}>
        <Card>
          <CardContent>
            <Title>Dank u wel!</Title>
            <Typography>
              U heeft een email ontvangen met de resultaten
            </Typography>
            <Typography>Uw referentie: </Typography>
            <Chip label={reference ? reference : ""} />
          </CardContent>
        </Card>
      </Grid>
    </Container>
  );
};

export default ThankYou;

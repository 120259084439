import {
  CardContent,
  Container,
  TextField,
  Button,
  Stack,
  Typography,
  Grid,
  List,
  ListItem,
  Autocomplete,
} from "@mui/material";

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useAnswersAdminPanelQuery,
  useQueryAllCustomersQuery,
} from "../redux/api";
import Title from "./Title";
import axios from "axios";
import { LoadingButton } from "@mui/lab";

function exportCSV(
  data:
    | {
        vraag?: string;
        categorie?: string;
        antwoord?: number;
        email?: string;
        naam_persoon?: string;
        naam_bedrijf?: string;
        created_at?: string;
        phone_numbers: string;
        num_empolyees: number;
        num_maintenance_budget: number;
        customer_id: string;
      }[][]
    | undefined,
  customer_id?: string
) {
  if (!data) {
    return;
  }
  // The first row are the headers.
  const rows = [
    [
      "customer_id",
      "vraag",
      "categorie",
      "antwoord",
      "email",
      "naam_persoon",
      "naam_bedrijf",
      "created_at",
      "telefoon nummber",
      "num_empolyees",
      "num_maintenance_budget",
    ],
  ];

  data.map((customer) => {
    customer.map((c) => {
      console.log("THIS IS THE DATE", customer_id, c);
      if (customer_id && c.customer_id !== customer_id) {
        return;
      }
      rows.push([
        c.customer_id,
        c?.vraag ?? "N/A",
        c?.categorie ?? "N/A",
        String(c?.antwoord ?? 0),
        c?.email ?? "N/A",
        c?.naam_persoon ?? "N/A",
        c?.naam_bedrijf ?? "N/A",
        c?.created_at ?? "N/A",
        c?.phone_numbers,
        String(c?.num_empolyees),
        String(c?.num_maintenance_budget),
      ]);
    });
  });

  let csvContent =
    "data:text/csv;charset=utf-8," + rows.map((e) => e.join(";")).join("\n");

  const encodedUri = encodeURI(csvContent);

  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "my_data.csv");
  document.body.appendChild(link); // Required for FF

  link.click();
}

const Export: React.FC = () => {
  const { id } = useParams();
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const login = async (password: string, name?: string) => {
    setError("");
    if (!password) {
      return Promise.reject("Voer een wachtwoord in");
    }

    console.log("Sending this password", password);
    const jwt = await axios
      .post(`${process.env.REACT_APP_AUTH_SERVER}/other/ipsolutions/auth`, {
        pass: password,
      })
      .then((res) => {
        console.log("This is the response", res);
        localStorage.setItem("jwt", res.data.token);
      })
      .catch((e) => {
        if (e.response.data.message) {
          return Promise.reject(e.response.data.message);
        } else {
          setError("Onbekende error");
          console.error(e);
        }

        return Promise.reject(e);
      });
    console.log("This is the JWT token", jwt);

    // localStorage.setItem("jwt", jwt);
  };

  if (localStorage.getItem("jwt")) {
    return <AdminPanel />;
  }

  return (
    <Container>
      <CardContent>
        <Title>Login</Title>
        <Stack spacing={2}>
          <Typography style={{ color: "red" }}>{error}</Typography>
          <TextField
            label="Wachtwoord"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <LoadingButton
            variant="contained"
            loading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await login(password).catch((e) => {
                setError(e);
              });
              setIsLoading(false);
            }}
          >
            Login
          </LoadingButton>
        </Stack>
      </CardContent>
    </Container>
  );
};

function AdminPanel() {
  const { data, error: errorQuery, isLoading } = useQueryAllCustomersQuery({});
  const { data: allData, error: errorAllQuery } = useAnswersAdminPanelQuery({});
  const { id } = useParams();
  const [name, setName] = useState("");
  const [inputValue, setInputValue] = useState<
    | {
        __typename?: "customers" | undefined;
        email: string;
        person_name: string;
        company_name: string;
        id: any;
        created_at: any;
      }
    | undefined
  >();

  const csvReport = {
    data: allData?.customers.map((c) => {
      const array = c.answers.map((a) => {
        return {
          customer_id: c.id,
          vraag: a.question.question ?? "N/A",
          categorie: a.question.category_name ?? "N/A",
          antwoord: a.answer ?? 0,
          email: c.email ?? "N/A",
          naam_persoon: c.person_name ?? "N/A",
          naam_bedrijf: c.company_name ?? "N/A",
          created_at: a.created_at ?? "N/A",
          phone_numbers: c.phone_numbers ?? "N/A",
          num_empolyees: c.num_empolyees ?? 0,
          num_maintenance_budget: c.num_maintenance_budget ?? 0,
        };
      });
      console.log("This is the array", array);

      return array;
    }),
  };

  console.log("This is the csv report", csvReport);

  const download = (customer_id?: string) => {
    exportCSV(csvReport.data, customer_id);
  };

  const jwt = localStorage.getItem("jwt");

  return (
    <Container>
      <CardContent>
        <Title>Export panel</Title>

        <Stack spacing={2}>
          <Autocomplete
            fullWidth
            onChange={(event: any, newValue: string | null) => {
              if (!newValue) {
                setInputValue(undefined);
                return;
              }

              const customer = newValue.split(" ");

              const c = data?.customers.find((u) => {
                return u.email === customer[0] && u.created_at === customer[1];
              });

              setInputValue(c);
            }}
            onInputChange={(event, newInputValue) => {
              // @ts-expect-e rror
              // setInputValue(newInputValue);
            }}
            id="free-solo-demo"
            freeSolo
            options={
              data?.customers.map(
                (customer) => `${customer.email} ${customer.created_at}`
              ) ?? []
            }
            renderInput={(params) => (
              <TextField key={"email-"} {...params} label="zoek op email" />
            )}
          />
          {/* <Autocomplete
            fullWidth
            onInputChange={(event, newInputValue) => {
              const getDate = /\s(.*)/;
              const customer = newInputValue.split(" ");

              const date = getDate.exec(newInputValue);
              console.log("Is this the date?", date);
              const c = data?.customers.find((u) => {
                return (
                  u.company_name === customer[0] && u.created_at === customer[1]
                );
              });

              setInputValue(c);
            }}
            id="free-solo-demo"
            freeSolo
            options={
              data?.customers.map(
                (customer) => `${customer.company_name} ${customer.created_at}`
              ) ?? []
            }
            renderInput={(params) => (
              <TextField
                key={"name-"}
                {...params}
                label="zoek op bedrijfsnaam"
              />
            )}
          /> */}

          <Button
            color="success"
            onClick={() => {
              if (inputValue) {
                const date = inputValue.created_at;

                if (date && date.length > 0) {
                  //   alert(date[0]);
                  console.log("this is the date:", date);
                  console.log(date[0]);

                  download(inputValue.id);
                } else {
                  download();
                }
              } else {
                download();
              }
            }}
          >
            {inputValue
              ? `Download alles van ${inputValue.company_name}`
              : "Download alles"}
          </Button>
          <Button
            color="error"
            onClick={() => {
              localStorage.removeItem("jwt");
              window.location.reload();
            }}
          >
            Logout
          </Button>
        </Stack>
      </CardContent>
    </Container>
  );
}

export default Export;
